.text-p3 {
  color: black;
  text-decoration: none;
  text-decoration-color: white;
  font-size: 2rem;
}

.text-p3:hover {
  cursor: pointer;
}

.email:hover {
  cursor: text;
}
.c-icon {
  height: 3.2rem;
  width: auto;
}

#contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 76vh;
}

.contact-info-upper-container {
  display: flex;
  justify-content: center;
  border-radius: 2rem;
  border: rgb(53, 53, 53) 0.1rem solid;
  border-color: rgb(163, 163, 163);
  background: (250, 250, 250);
  margin: 3.2rem auto;
  padding: 0.8rem;
}

.contact-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  margin: 1.6rem;
}

footer {
  height: 25vh;
  margin: 0 1rem;
}

.text-p4 {
  font-size: 1.6rem;
  text-align: center;
}

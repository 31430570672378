.slideshow-container {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  margin: auto;
}

.slide {
  width: 100%;
  height: 30rem;
  display: block;
  transition: opacity 0.5s ease-in-out;
  border-radius: 2rem;
}

.arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 9;
}

.arrow-left {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  position: relative;
  color: #9a9a9a;
}
.arrow-right {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  position: relative;
  color: #9a9a9a;
}

/*NAV*/
nav,
.nav-links {
  display: flex;
}

nav {
  justify-content: space-around;
  align-items: center;
  height: 17vh;
}

.nav-links {
  gap: 4rem;
  list-style: none;
  font-size: 2.4rem;
}

a {
  color: black;
  text-decoration: none;
  text-decoration-color: white;
  position: relative;
}

a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-bottom: 2.5px solid grey;
  transform: scaleX(0);
  transition: transform 0.3s;
}

a:hover::before {
  color: grey;
  text-decoration: underline;
  text-underline-offset: 1rem;
  transform: scaleX(1);
  width: 100%;
}

.logo {
  font-size: 3rem;
}
.logo:hover {
  cursor: default;
}

.nav-icon {
  height: 4rem;
  width: 4rem;
  cursor: pointer;
}

/*HAMBURGER NAV*/
.hamburger-nav {
  display: none;
}

.hamburger-menu {
  position: relative;
  display: inline-block;
}

.nav-links-hamburger {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  width: fit-content;
  max-height: 300;
  transition: all 0.3 ease-in-out;
}

.nav-links-hamburger a {
  display: block;
  padding: 10px;
  text-align: center;
  font-size: 2.4rem;
  color: block;
  text-decoration: none;
  transition: all 0.3 ease-in-out;
  background-color: #f5f1ee;
}

.nav-links-hamburger a::before {
  transform: none;
  text-decoration: none;
}

.nav-links-hamburger a::before {
  border-bottom: none;
}
.nav-links-hamburger li {
  list-style: none;
}
